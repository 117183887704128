import {
  Box,
  Button,
  Flex,
  Heading,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsPerson, BsChat, BsWhatsapp } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import grainbgboxcont from "../../assets/images/grain-box-contact.png";
import grainbgbox from "../../assets/images/grain-box.png";

const url =
  "https://hotmail.us21.list-manage.com/subscribe/post?u=a82ace8161f9adf39e18b34d2&amp;id=73e2f745a0&amp;f_id=00bff1e6f0";

export default function ContactFormWithSocialButtons() {
  return (
    <Flex
      bg={useColorModeValue("#F9F0DF", "#F9F0DF")}
      w={"full"}
      align={"center"}
      justify={"flex-end"}
      id="contact"
      flexDir={"row"}
      px={{ base: 5, md: 16 }}
      py={{ base: 100, md: 200 }}
      mb={{ base: 10, md: 0 }}
    >
      <Box
        px={10}
        // border={"solid 3px #DD9331"}
        bgColor={"#DD9331"}
        bgRepeat={{ base: "repeat-x", md: "repeat-y" }}
        bgImage={{ base: grainbgbox, md: grainbgboxcont }}
        bgBlendMode={"multiply"}
        bgPos={"40% 100%"}
        borderRadius="25px"
        w={"full"}
      >
        <Stack direction={{ base: "column", md: "row" }} spacing={{ base: 4, md: 8, lg: 8 }}>
          <Stack
            spacing={{ base: 4, md: 8, lg: 20 }}
            direction={{ base: "row", md: "row" }}
            w={"100%"}
            pt={{ base: 5, md: 0 }}
            pb={{ base: 10, md: 0 }}
            justifyContent={{ base: "center", md: "start" }}
          >
            <Heading
              fontSize={{
                base: "4xl",
                md: "6xl",
                lg: "8xl",
              }}
              fontWeight={900}
              textTransform={"uppercase"}
              color={"#333333"}
              alignContent={"center"}
              // textShadow={"5px 5px #000;"}
            >
              <Box display={{ base: "none", md: "block" }}>
                Let's
                <br />
                Get in
                <br />
                Touch
              </Box>
              <Box textAlign={"center"} display={{ base: "block", md: "none" }}>
                Let's Get in Touch
              </Box>

              <Box display={{ base: "none", md: "block" }}></Box>
            </Heading>
          </Stack>
          <Stack
            spacing={{ base: 4, md: 8, lg: 10 }}
            direction={{ base: "row", md: "row" }}
            // w={{ base: "90vw", md: "50vw" }}
            w={"100%"}
          >
            <Box
              bg={useColorModeValue("white", "gray.700")}
              borderRadius="15px"
              px={6}
              py={12}
              w={"full"}
              color={useColorModeValue("gray.700", "whiteAlpha.900")}
              shadow="xl"
              my={-10}
            >
              <VStack spacing={5}>
                <Flex flexDir={{ base: "column", lg: "row" }} alignItems="center">
                  <Link
                    _hover={{ textDecoration: "none" }}
                    display={"flex"}
                    href="mailto:nathanmls@hotmail.com"
                    isExternal
                    mr={{ base: 0, lg: 4 }}
                    mb={{ base: 4, lg: 0 }}
                    fontSize={"10px"}
                  >
                    <Button justifyContent={"flex-start"} alignItems={"center"}>
                      <MdOutlineEmail size="20px" color="#1d4036" />
                      <Box display={{ base: "none", sm: "inherit" }} pl={"10px"}>
                        nathanmls@hotmail.com
                      </Box>
                      <Box display={{ base: "inherit", sm: "none" }} pl={"10px"}>
                        Email
                      </Box>
                    </Button>
                  </Link>
                  <Link
                    _hover={{ textDecoration: "none" }}
                    display={"flex"}
                    href="https://wa.me//31627293829"
                    isExternal
                  >
                    <Button
                      color="#1d4036"
                      _hover={{ color: "#25d366" }}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <BsWhatsapp size="20px" />
                      <Box pl={"10px"}>WhatsApp</Box>
                    </Button>
                  </Link>
                </Flex>
                <hr />
                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <div id="mc_embed_signup" style={{ width: "100%" }}>
                      <form
                        action={url}
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                      >
                        {/* Embedded form fields */}

                        <Stack id="mc_embed_signup_scroll" w={{ base: "100%", md: "100%" }}>
                          <InputGroup>
                            <InputLeftElement h="full" pointerEvents="none">
                              <BsPerson color="#999999" />
                            </InputLeftElement>
                            <input
                              style={{ paddingLeft: "40px" }}
                              type="text"
                              name="FNAME"
                              placeholder="First Name"
                            />
                          </InputGroup>
                          <InputGroup>
                            <InputLeftElement h="full" pointerEvents="none">
                              <MdOutlineEmail color="#999999" />
                            </InputLeftElement>
                            <input
                              style={{ paddingLeft: "40px" }}
                              type="text"
                              name="EMAIL"
                              placeholder="Email"
                            />
                          </InputGroup>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <BsChat color="#999999" />
                            </InputLeftElement>
                            <textarea
                              style={{ paddingLeft: "40px" }}
                              type="text"
                              name="MESSAGE"
                              placeholder="Message"
                            />
                          </InputGroup>

                          {/* Other fields */}
                        </Stack>

                        {/* Response divs */}
                        <div id="mce-responses">
                          <div class="response" id="mce-error-response"></div>
                          <div class="response" id="mce-success-response"></div>
                        </div>

                        {/* Submit button */}
                        <input
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          class="button"
                        />
                      </form>
                    </div>
                  )}
                />
              </VStack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
}
