import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Slider from "react-slick";

function Trainee() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    swipeToSlide: false,
    cssEase: "ease-in-out",
    draggable: false,
    variableWidth: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const words = [
    { text: "Marketing Trainee", company: "IK Events & Productions", year: 2015 },
    { text: "Marketing Trainee", company: "Vetta Group", year: 2014 },
    { text: "Marketing Trainee", company: "Gebauer", year: 2013 },
    { text: "Administration Trainee", company: "CDL Contagem", year: 2012 },
    { text: "Graphic Design Trainee", company: "ESMU - Music School", year: 2012 },
    { text: "Computer Instructor Trainee", company: "Cedaspy", year: 2009 },
  ];

  return (
    <Slider {...settings}>
      {words.map((word) => (
        <Flex alignContent={"start"} pt={10} key={word.text} w="200px" h="200px" bg="#E5D5B8">
          <Text color={"#1D4036"} fontSize={22} fontWeight={"800"} px={"10px"}>
            {word.year}
          </Text>
          <Box
            _before={{
              content: `""`,
              w: "10px",
              h: "10px",
              borderColor: `#1D4036`,
              borderStyle: "solid",
              borderWidth: "5px",
              position: "relative",
              backgroundColor: `#E5D5B8`,
              rounded: "50%",
              top: "-8.5px",
              left: "10px",
            }}
            display={"flex"}
            as="span"
            w={"100%"}
            bg={"#1D4036"}
            my={3}
            h="3px"
          ></Box>
          <Text px={"10px"} color={"#1D4036"} fontSize="2xl" lineHeight={1} fontWeight="700">
            {word.text}
          </Text>
          <Text px={"10px"} fontSize="lg" fontWeight="600" w="100%" color={"#A45826"}>
            {word.company}
          </Text>
        </Flex>
      ))}
    </Slider>
  );
}

export default Trainee;
