import React from "react";
import { Flex } from "@chakra-ui/react";
import mountain from "../../assets/images/mountain.png";

const HillsDivider = ({ m }) => {
  return (
    <>
      <Flex
        pos={"absolute"}
        h={{ base: "417px", sm: "417px", md: "417px" }}
        w={"100%"}
        m={m}
        backgroundImage={mountain}
        bgRepeat={"repeat-x"}
        bgPos={"top center"}
        // backgroundSize={"100% 100%"}
        zIndex={4}
      ></Flex>
    </>
  );
};

export default HillsDivider;
