import Header from "./components/molecules/Header";
import Hero from "./components/organisms/Hero";
import About from "./components/organisms/About";
import { ChakraProvider } from "@chakra-ui/react";
import Journey from "./components/organisms/Journey";
import Showcase from "./components/organisms/Showcase";
import Contact from "./components/organisms/Contact";
import Footer from "./components/molecules/Footer";
import Trainee from "./components/organisms/Trainee";
import PreLoader from "./components/PreLoader";

function App() {
  return (
    <>
      <PreLoader />
      <div className="App">
        <ChakraProvider>
          <Header />
          <Hero />
          <About />
          <Journey />
          <Trainee />
          <Showcase />
          <Contact />
          <Footer />
        </ChakraProvider>
      </div>
    </>
  );
}

export default App;
