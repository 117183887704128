import React, { useEffect, useState } from "react";
import grain from "../../assets/images/grain-sun.png";
import grainbg from "../../assets/images/grain-bg-sun.png";
import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Sun = () => {
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationStarted(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex
      pos={"absolute"}
      w={{ base: "100%", sm: "500px" }}
      h={{ base: "100vw", sm: "500px" }}
      justifyContent={"center"}
    >
      <motion.div
        initial={{ translateY: 40 }}
        animate={isAnimationStarted ? { translateY: 0 } : { translateY: 40 }}
        transition={{ duration: 4, ease: "easeInOut" }}
        width={{ base: "100%", sm: "500px" }}
      >
        <Flex
          pos="absolute"
          bottom={"0"}
          w={"auto"}
          justifyContent={"center"}
          justifySelf={"center"}
        >
          <Flex
            pos={"absolute"}
            zIndex={2}
            opacity={1}
            aspectRatio={1}
            bgPos={"center"}
            w={{ base: "100vw", sm: "500px" }}
            h={{ base: "100vw", sm: "500px" }}
            bottom={0}
            rounded={"full"}
            backgroundImage={grain}
            backgroundColor={"#DD9331"}
            bgRepeat={"repeat-x"}
          ></Flex>
          <Flex
            zIndex={1}
            pos={"absolute"}
            bottom={"-140px"}
            h={"748px"}
            w={{ base: "100vw", md: "748px" }}
            display={{ base: "flex", md: "none", xl: "flex" }}
            bgSize={"cover"}
            bgRepeat={"no-repeat"}
            bgPosition={"center center"}
            opacity={0.3}
            backgroundImage={grainbg}
          ></Flex>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default Sun;
