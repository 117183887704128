import * as React from "react";
import {
  chakra,
  Container,
  Stack,
  Text,
  Box,
  Image,
  VStack,
  Heading,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import profile from "../../assets/images/nathan-martins-profile-picture.jpg";
import grainbgbox from "../../assets/images/grain-box.png";

const About = () => {
  return (
    <>
      <Box
        zIndex={0}
        w={"100%"}
        h={"auto"}
        backgroundColor={"#115945"}
        // bg={`url(${grainbgbox}) #115945`}
        // bgPosition={{ base: "100% 110%", md: "100% 150%" }}
        // bgRepeat={"repeat-x"}
        // bgBlendMode={"multiply"}
        // bg={"url('./noise-bg.png') #115945"}
      >
        <VStack zIndex="5" pos={"relative"} id="aboutme" pb={"120px"}>
          <Container maxW={"1200px"} px={{ base: 4, lg: 6, md: 6 }} pt={12} zIndex={1}>
            <Stack mb={"5rem"} direction={{ base: "column", md: "row" }} justifyContent="center">
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "linear", delay: 0.2 }}
                h={"100%"}
                style={{ opacity: 0, y: "2vw" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignSelf={"center"}
                  mb={{ base: "30px", md: 0 }}
                  mr={{ base: "16px", md: 5 }}
                  pos="relative"
                >
                  <Image
                    shadow={"18px 18px 0 #DD9331"}
                    w={{ base: "60vw", md: "30vw" }}
                    minW={{ base: "100px", sm: "300px" }}
                    roundedTop={"full"}
                    roundedBottom={"20px"}
                    src={profile}
                    backgroundPosition={"center"}
                    // src="./nathan-martins-profile-picture.jpg"
                    alt="Nathan Martins"
                  />
                </Box>
              </motion.div>
              <Stack
                direction="column"
                spacing={6}
                justifyContent="center"
                maxW="650px"
                px={"5"}
                textAlign={{ base: "center", md: "left" }}
              >
                <motion.div
                  whileInView={{ opacity: 1, y: "0" }}
                  transition={{ ease: "linear", delay: 0.4 }}
                  h={"100%"}
                  style={{ opacity: 0, y: "2vw" }}
                >
                  <chakra.h1
                    color={"#DD9331"}
                    fontSize="40px"
                    lineHeight={1}
                    pb={5}
                    fontWeight="bold"
                  >
                    Hey There!
                  </chakra.h1>
                  <Text lineHeight="1.375" fontWeight="400" color="#F9F0DF">
                    <p>
                      I am Nathan, a Brazilian advertiser currently based in Amsterdam. With a
                      strong background in <strong>visual and graphic designing</strong>, I
                      specialized in creating <strong>visually appealing content</strong> for a
                      multitude of media platforms. My expertise also extends to{" "}
                      <strong>UX/UI</strong> <strong>designing</strong> and{" "}
                      <strong>front-end development</strong>, focused on{" "}
                      <strong>enabling effective and responsive layouts</strong>. Additionally, I
                      have vast experience in <strong>video production</strong> and{" "}
                      <strong>motion graphics</strong>, allowing me to create{" "}
                      <strong>ever-engaging and dynamic pieces</strong>.
                    </p>
                    <br />
                    <p>
                      I am proficient in utilizing top tier creation tools such as{" "}
                      <strong>
                        Figma, Adobe XD, Photoshop, InDesign, Illustrator, Premiere, and After
                        Effects
                      </strong>
                      . Over the years, I have garnered{" "}
                      <strong>
                        extensive experience working with a diverse set of fields in the industry
                      </strong>
                      , including Retail, Healthcare, IT, Digital Marketing, Civil
                      Engineering/Architecture, and Events Production - to name a few.
                    </p>
                    <br />
                    <p>
                      Hiring me, you are getting a{" "}
                      <strong>confident, adaptable and accommodating</strong> team member, as my
                      decision-making approach ultimately led myself and any project I’ve worked
                      with to successful outcomes. To foster innovation and go above and beyond
                      expectations, I am constantly striving to{" "}
                      <strong>stay ahead of the curve on the industry trends</strong> and{" "}
                      <strong>proactively acquire new skills</strong>.
                    </p>
                  </Text>
                </motion.div>
              </Stack>
            </Stack>

            <SimpleGrid
              direction={{ base: "column", md: "row" }}
              verticalAlign={"baseline"}
              columns={{ base: "1", sm: "2", md: "3", lg: "5" }}
              spacingY={"30px"}
              spacingX={"30px"}
              justifyContent="center"
            >
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                style={{ opacity: 0, y: "2vw" }}
                transition={{ ease: "linear", delay: 0 }}
                h={"100%"}
              >
                <Box
                  color={"#F9F0DF"}
                  textAlign={"center"}
                  mb={{ base: "0", md: 0 }}
                  pos="relative"
                >
                  <Box
                    w={{ base: "210px", lg: "100%" }}
                    display={"inline-grid"}
                    border={"3px solid rgba(0, 0, 0, 0.26);"}
                    mb={"10px"}
                    rounded={"15px"}
                    backgroundImage={grainbgbox}
                    backgroundBlendMode={"multiply"}
                    backgroundPosition={"center"}
                    backgroundColor={"#115945"}
                    backgroundSize={"cover"}
                  >
                    <SimpleGrid
                      display={"inline-grid"}
                      columns="3"
                      spacingX="13px"
                      spacingY="13px"
                      p="4"
                    >
                      <Tooltip label="HTML">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/html.svg"
                          alt="HTML"
                        />
                      </Tooltip>
                      <Tooltip label="CSS">
                        <Image
                          _hover={{ transform: "scale(1.2)", trtansition: "all .2s ease-in-out" }}
                          src="./skills-icons/css.svg"
                          alt="CSS"
                        />
                      </Tooltip>
                      <Tooltip label="SEO">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/seo.svg"
                          alt="SEO"
                        />
                      </Tooltip>
                      <Tooltip label="ReactJS">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/react.svg"
                          alt="ReactJS"
                        />
                      </Tooltip>
                      <Tooltip label="Wordpress">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/wordpress.svg"
                          alt="Wordpress"
                        />
                      </Tooltip>
                      <Tooltip label="JavaScript">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/js.svg"
                          alt="JavaScript"
                        />
                      </Tooltip>
                    </SimpleGrid>
                  </Box>
                  <Heading mb="10px" size="md">
                    Front-Page Dev
                    <br /> and SEO
                  </Heading>
                  <Text fontSize={"small"}>HTML, CSS, Java Script, Wordpress, React JS</Text>
                </Box>
              </motion.div>
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "linear", delay: 0.2 }}
                h={"100%"}
                style={{ opacity: 0, y: "2vw" }}
              >
                <Box
                  color={"#F9F0DF"}
                  textAlign={"center"}
                  mb={{ base: "30px", md: 0 }}
                  pos="relative"
                >
                  <Box
                    w={{ base: "210px", lg: "100%" }}
                    display={"inline-grid"}
                    border={"3px solid rgba(0, 0, 0, 0.26);"}
                    mb={"10px"}
                    rounded={"15px"}
                    backgroundImage={grainbgbox}
                    backgroundBlendMode={"multiply"}
                    backgroundPosition={"center"}
                    backgroundColor={"#115945"}
                    backgroundSize={"cover"}
                  >
                    <SimpleGrid
                      display={"inline-grid"}
                      columns="3"
                      spacingX="13px"
                      spacingY="13px"
                      p="4"
                    >
                      <Tooltip label="Figma">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/figma.svg"
                          alt="Figma"
                        />
                      </Tooltip>
                      <Tooltip label="Adobe XD">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/adobe-xd.svg"
                          alt="Adobe XD"
                        />
                      </Tooltip>
                      <Tooltip label="Hotjar">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/hotjar.svg"
                          alt="Hotjar"
                        />
                      </Tooltip>
                    </SimpleGrid>
                  </Box>
                  <Heading mb="10px" size="md">
                    UX/UI + <br />
                    Design Thinking
                  </Heading>
                  <Text fontSize={"small"}>
                    Figma, Adobe XD, Hotjar, User Journey, A/B Test, Benchmark, React UI
                  </Text>
                </Box>
              </motion.div>
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "linear", delay: 0.4 }}
                h={"100%"}
                style={{ opacity: 0, y: "2vw" }}
              >
                <Box
                  color={"#F9F0DF"}
                  textAlign={"center"}
                  mb={{ base: "30px", md: 0 }}
                  pos="relative"
                >
                  <Box
                    w={{ base: "210px", lg: "100%" }}
                    display={"inline-grid"}
                    border={"3px solid rgba(0, 0, 0, 0.26);"}
                    mb={"10px"}
                    rounded={"15px"}
                    backgroundImage={grainbgbox}
                    backgroundBlendMode={"multiply"}
                    backgroundPosition={"center"}
                    backgroundColor={"#115945"}
                    backgroundSize={"cover"}
                  >
                    <SimpleGrid
                      display={"inline-grid"}
                      columns="3"
                      spacingX="13px"
                      spacingY="13px"
                      p="4"
                    >
                      <Tooltip label="Photoshop">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/photoshop.svg"
                          alt="Photoshop"
                        />
                      </Tooltip>
                      <Tooltip label="Illustrator">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/illustrator.svg"
                          alt="Illustrator"
                        />
                      </Tooltip>
                      <Tooltip label="Premiere">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/premiere.svg"
                          alt="Premiere"
                        />
                      </Tooltip>
                      <Tooltip label="Adobe XD">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/adobe-xd.svg"
                          alt="Adobe XD"
                        />
                      </Tooltip>
                      <Tooltip label="After Effects">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/after-effects.svg"
                          alt="After Effects"
                        />
                      </Tooltip>
                    </SimpleGrid>
                  </Box>
                  <Heading mb="10px" size="md">
                    Visual Softwares
                  </Heading>
                  <Text fontSize={"small"}>
                    Photoshop, Illustrator, InDesign, Premiere, After Effects
                  </Text>
                </Box>
              </motion.div>
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "linear", delay: 0.6 }}
                h={"100%"}
                style={{ opacity: 0, y: "2vw" }}
              >
                <Box
                  color={"#F9F0DF"}
                  textAlign={"center"}
                  mb={{ base: "30px", md: 0 }}
                  pos="relative"
                >
                  <Box
                    w={{ base: "210px", lg: "100%" }}
                    display={"inline-grid"}
                    border={"3px solid rgba(0, 0, 0, 0.26);"}
                    mb={"10px"}
                    rounded={"15px"}
                    backgroundImage={grainbgbox}
                    backgroundBlendMode={"multiply"}
                    backgroundPosition={"center"}
                    backgroundColor={"#115945"}
                    backgroundSize={"cover"}
                  >
                    <SimpleGrid
                      display={"inline-grid"}
                      columns="3"
                      spacingX="13px"
                      spacingY="13px"
                      p="4"
                    >
                      <Tooltip label="Blender">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/blender.svg"
                          alt="Blender"
                        />
                      </Tooltip>
                      <Tooltip label="Dimension">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/dimension.svg"
                          alt="Dimension"
                        />
                      </Tooltip>
                      <Tooltip label="ScketchUp">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/sketchup.svg"
                          alt="ScketchUp"
                        />
                      </Tooltip>
                    </SimpleGrid>
                  </Box>
                  <Heading mb="10px" size="md">
                    3D
                  </Heading>
                  <Text fontSize={"small"}>Blender, Sketchup, Dimension, After Effects</Text>
                </Box>
              </motion.div>
              <motion.div
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "linear", delay: 0.8 }}
                h={"100%"}
                style={{ opacity: 0, y: "2vw" }}
              >
                <Box
                  color={"#F9F0DF"}
                  textAlign={"center"}
                  mb={{ base: "30px", md: 0 }}
                  pos="relative"
                >
                  <Box
                    w={{ base: "210px", lg: "100%" }}
                    display={"inline-grid"}
                    border={"3px solid rgba(0, 0, 0, 0.26);"}
                    mb={"10px"}
                    rounded={"15px"}
                    backgroundImage={grainbgbox}
                    backgroundBlendMode={"multiply"}
                    backgroundPosition={"center"}
                    backgroundColor={"#115945"}
                    backgroundSize={"cover"}
                  >
                    <SimpleGrid
                      display={"inline-grid"}
                      columns="3"
                      spacingX="13px"
                      spacingY="13px"
                      p="4"
                    >
                      <Tooltip label="Leonardo AI">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/leonardo-ai.png"
                          alt="Leonardo AI"
                        />
                      </Tooltip>
                      <Tooltip label="Stable Diffusion">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/stable-diffusion.svg"
                          alt="Stable Diffusion"
                        />
                      </Tooltip>
                      <Tooltip label="Chat GPT (openai)">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/chat-gpt-openai.svg"
                          alt="Chat GPT (openai)"
                        />
                      </Tooltip>
                      <Tooltip label="Photoshop Beta">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/photoshop-beta.svg"
                          alt="Photoshop Beta"
                        />
                      </Tooltip>
                      <Tooltip label="Midjourney">
                        <Image
                          _hover={{ transform: "scale(1.2)", transition: "all .2s ease-in-out" }}
                          src="./skills-icons/midjourney.svg"
                          alt="Midjourney"
                        />
                      </Tooltip>
                    </SimpleGrid>
                  </Box>
                  <Heading mb="10px" size="md">
                    AI Tools
                  </Heading>
                  <Text fontSize={"small"}>
                    Generative Fill, Chat-GPT, Midjourney, Leonardo AI, Stable Diffusions
                  </Text>
                </Box>
              </motion.div>
            </SimpleGrid>
          </Container>
        </VStack>
      </Box>
    </>
  );
};

export default About;
