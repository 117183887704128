import * as React from "react";
import { chakra, Container, Stack, Text, Box, Flex } from "@chakra-ui/react";
import CarouselHero from "../atoms/CarouselHero";
import Sun from "../atoms/Sun";
import HillsDivider from "../atoms/HillsDivider";
import grainbg from "../../assets/images/grain-bg-sun.png";

export default function Hero() {
  return (
    <>
      <Flex
        pos={"absolute"}
        zIndex={1}
        opacity={0.2}
        aspectRatio={1}
        bgPos={"center"}
        backgroundSize={"contain"}
        w={{ base: "914px", md: "914px" }}
        h={"914px"}
        // transform={"scale(1.8)"}
        top={"-40em"}
        left={{ base: "-40em", sm: "-28em", md: "-30em" }}
        rounded={"full"}
        blendMode={"multiply"}
        backgroundImage={grainbg}
        bgRepeat={"no-repeat"}
      ></Flex>
      <Container maxW={"1200px"} px={{ base: 4, md: 4 }} py={20}>
        <Stack direction={{ base: "column", md: "row" }} justifyContent="center">
          <Stack
            direction="column"
            spacingy={3}
            alignSelf="center"
            maxW="500px"
            mx={{ base: "25px", md: "0" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <chakra.h1 color={"#DD9331"} fontSize="43" lineHeight={1} fontWeight="bold">
              Hi! I Am
              <br />
              <chakra.span color="#1D4036">Nathan Martins</chakra.span>
            </chakra.h1>
            <Text fontSize="16" lineHeight="1.375" fontWeight="400" color="#1D4036">
              Advertiser and Front-End Developer specialized in{" "}
              <chakra.span fontWeight="600">UX Design</chakra.span>. I love to bring ideas to life
              through designs that inspires, communicates, and connects people.
            </Text>
          </Stack>
          <Box
            mt={{ base: "4rem", md: 0 }}
            ml={{ base: 0, md: 2 }}
            display="flex"
            alignSelf={"center"}
          >
            <CarouselHero />
            <Sun />
          </Box>
        </Stack>
      </Container>
      <HillsDivider m={"-180px 0 0 0"} />
    </>
  );
}
