import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations";
import "../styles/preloader.scss";
import { Box, Image } from "@chakra-ui/react";
import logon from "../assets/logo-icons/n.svg";
import logoa from "../assets/logo-icons/a.svg";
import logot from "../assets/logo-icons/t.svg";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      <div className="logo-container">
        <Box mr={8}>
          <Image h={"40px"} src={logon} />
        </Box>
        <Box mr={-9}>
          <Image h={"40px"} src={logoa} />
        </Box>
        <Box>
          <Image h={"40px"} src={logot} />
        </Box>
      </div>
    </div>
  );
};

export default PreLoader;
